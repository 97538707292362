<template>
<div>
    <v-container>
        <v-layout row wrap>
            <h1>Bienvenido al panel de administración (SOLICITANTE)</h1>
        </v-layout>
    </v-container>
    <v-container grid-list-md text-xs-center class="pa-3  white">
        <v-layout>
            <v-flex xs6 row justify-center align-center d-flex>
                <v-img
                   src="/images/actividad/comienza.png"
                   width="50px"
                    ></v-img>
                <div text-xs-left>
                    <h3 class="text-uppercase">{{ `${this.$store.state.user.nombre} ${this.$store.state.user.app_pat}`}}</h3>
                    <h3 class="text-uppercase">Membresía comienza</h3>
                </div>
            </v-flex>
            <v-flex xs6 >
                <v-container fluid class="fondogris" >
                    <v-layout  row>
                        <v-flex d-flex xs12 md12 lg12  sm3 >
                            <h5 class="text-uppercase">Nivel membresía</h5>
                        </v-flex>
                    </v-layout>
                    <v-layout  row>
                        <v-flex xs4 md4 lg4d-flex  sm3 class="centrar" align-center justify-center  >
                            <v-img
                            src="/images/actividad/comienza.png"
                            width="40px"
                            ></v-img>
                            <div width="100%" class="circleetapa1 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorcomienza">comienza</h5>
                            </div>
                        </v-flex>
                        <v-flex  xs4 md4 d-flex lg4 sm3 class="centrar">
                            <v-img
                            src="/images/actividad/crece.png"
                            width="40px"
                            ></v-img>
                            <div width="100%" class="circleetapa2 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorcrece ">crece</h5>
                            </div>
                        </v-flex>
                        <v-flex  xs4 md4 d-flex lg4 sm3 class="centrar">
                            <v-img
                            src="/images/actividad/expande.png"
                            width="40px"
                            ></v-img>
                            <div width="100%" class="circleetapa3 mt-5 pt-3 pb-3">
                                <h5 class="text-uppercase colorexpande  ">expande</h5>
                            </div>
                        </v-flex>
                    </v-layout>
               </v-container>
               <v-progress-linear
                        color="progress1"
                        height="25"
                        value="75"
                        class="progressradius"
                        >6 puntos</v-progress-linear>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs4 offset-xs4 d-flex justify-center align-center class="pa-3">
                <v-img src="/images/actividad/vermasiz.png"  width="20px"></v-img>
                <h1>SABER MÁS</h1>
                 <v-img src="/images/actividad/vermasder.png"  width="20px"></v-img>
            </v-flex>
        </v-layout>
        <v-layout class="pa-3"  d-flex justify-center align-center>
            <v-flex xs2  d-flex justify-center align-center>
                 <v-img src="/images/actividad/vermas.png"  width="50px"></v-img>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs12 text-xs-left>
              <p> 
                  <h4> Estimados,</h4>
              
                 <p>  <b> Para RedCapital es muy importante poder ayudar y ser un real apoyo para nuestros clientes. Por esta razón, cada semana durante un mes, compartiremos con ustedes material de educación financiera, que los ayudará a hacer crecer sus negocios. Al finalizar la lectura de los documentos encontrarán algunas actividades sencillas relacionadas con el tema tratado. Al completar las actividades se irán sumando puntos los que se traducirán en diferentes niveles de membresía y, por tanto, de beneficios con los cuales contarán.
                   </b></p>
                   <p> Queremos contarte un poco más acerca de las membresías; en qué consisten y cuales son los beneficios a los cuales podrás acceder.
                       </p>
                     <h5> ACERCA DEL BLOG</h5>
                    <p>RedCapital entregará material de educación financiera mediante entradas de su blog, las cuales serán compartidas con los usuarios mediante un mail semanal. Inicialmente serán 4 grandes temas 1.Organiza tus finanzas, 2.Controla tus costos, 3.Planifica tu futuro y 4.Gestiona tu deuda.
                    </p>
                   <h5> ACERCA DE LOS DESAFÍOS/ACTIVIDADES</h5>
                    <p>En cada entrada del blog mencionado anteriormente, encontrarás al final de la lectura una sección con diferentes actividades relacionadas al tema sobre el cual acabas de leer. La idea es que puedas ir realizándolas, ya sea todas de una sola vez o al ritmo que tu elijas. Por cada actividad realizada irás sumando puntos.
                    </p>
                   <h5> ACERCA DE LOS PUNTOS</h5>
                    <p>Los puntos se obtienen a medida que el usuario va completando los distintos desafíos que puede encontrar en cada una de las cuatro temáticas de nuestro blog.
                    Cada actividad otorga 1 punto al ser completada por el usuario. los puntos son independientes de la temática, es decir, se suman por igual no importando de cuál tema sea la o las actividades realizadas.
                    Al ir sumando puntos, estos te servirán para avanzar en tu nivel de membresía y así poder obtener mejores beneficios.
                    </p>
                    <h5>ACERCA DE LAS MEMBRESÍAS</h5>
                   <p> Las membresías constan de tres niveles: nivel 1. “Comienza”, luego nivel 2. “Crece” y finalmente nivel 3. “Expande”.
                    Cada nivel está determinado por un rango específico de puntaje, siendo los siguientes: de 0 a 9 puntos corresponde al primer nivel, luego de 9 a 12 puntos el segundo nivel y de 12 a 15 puntos el nivel final. 
                    Cada nivel de membresía permite al usuario acceder a ciertos beneficios específicos, los cuales van mejorando a medida que se va subiendo de nivel.</p>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs12>
                <v-btn @click="godashboard()" class="btnvolver">
                    <h3>VOLVER</h3>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
@Component({
    data() {
      return {
            e1: 1,
        }
    },methods:{
        godashboard(){
           /* this.$router.push({
                path: "/actividad/administracion"
            })*/
            this.$router.go(-1)
        }
    }
})
export default class Sabermas extends Vue {}
</script>

<style scoped>
.btnvolver{
    background-color: #6DC9F7 !important;
    color: #fff !important;
    padding: 10px 15px;
}
.fullwidth{
    width: 100%;
}
.headline {
    border-bottom: 1px solid #fff !important;
    padding-bottom: 5px;
}
.positionabsolute{
    position: absolute;
    top: 0;
    right: 0;
}
.positionrelative{
    position: relative;
}
.progressradius{
    border-radius: 15px;
    color: white;
    font-weight: 600;
    font-size: 1em;
}
.text-center{
    text-align: center;
}
.justify-content-center{
    justify-content: center;
}
.align-items-center{
    align-items: center;
}
.centrar{
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.circleetapa1::before {
    content: "1";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #24AE94;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress1{
     background: #B8EAE0;
}
.circleetapa2::before {
    content: "2";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #BE75F7;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress2{
     background: #BE75F7;
}
.circleetapa3::before {
    content: "3";
    width: 30px;
    height: 30px;
    position: absolute;
    background: #FCAF22;
    top: -15px;
    left: calc(50% - 15px);
    background-repeat: no-repeat;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress3{
     background: #FCAF22;
}
.circleetapa1, .circleetapa2, .circleetapa3 {
    position: relative;
}
.circleetapa1 h3 {
    color: #24AE94;
}
.circleetapa2 h3 {
    color: #BE75F7;
}
.circleetapa3 h3 {
    color: #FCAF22;
}
.colornegro{
    color: #000 !important;
}
.colorblanco{
    color: #fff;
}
.colorcomienza{
    color: #24AE94;
}
.colorcrece{
    color: #BE75F7;
}
.colorexpande{
    color:#FCAF22;
}
.fondogris{
    background: #F4F4F4;
    border-radius: 10px;
}
.fondodisabled{
    background-color: #B8F6EA !important;
}
.fondopuntos{
    background: #BAD8D2;
    width: 100%;
}
.btn-color{
    background:#92AFAF !important;
}
.btn-colorpendientes{
      background:#D36523 !important;
}
.fondopendientes{
    background: #14E3BB !important;
}
.fondopuntospendientes{
    background:#1C7E6B;
     width: 100%;
}
.colorbeneficios{
    color: #3EAFD2;
}
.colorbeneficiosdisabled{
    color: #CCC7C7;
}
</style>